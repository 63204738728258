.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.hippo-image {
  max-height: 300px;
  max-width: 95%;
  margin-bottom: 20px;
}

.footer {
  font-size: 15px;
}
